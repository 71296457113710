:root {
  --f_heading: "KBCloudyDay";
}

@font-face {
  font-family: 'KBCloudyDay';
  src: url('fonts/KBCloudyDay.eot');
  src: url('fonts/KBCloudyDay.eot?#iefix') format('embedded-opentype'),
      url('fonts/KBCloudyDay.woff2') format('woff2'),
      url('fonts/KBCloudyDay.woff') format('woff'),
      url('fonts/KBCloudyDay.ttf') format('truetype'),
      url('fonts/KBCloudyDay.svg#KBCloudyDay') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.logo-name {
  font-family: var(--f_heading);
}

.navbar-nav > li {
  margin-right: 40px;
  display: flex;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #000000;
  opacity: 1;
}

.navbar-nav > li > ul li a {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #000000;
  opacity: 1;
  padding-top: 10px;
  padding-bottom: 10px;
}

.nav-btn {
  background-color: #FFFFFF;
  border: 3px solid #000000;
  border-radius: 25px;
  padding: 0 1.10rem;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-btn:not(:last-child) {
  margin-right: 20px;
}

.nav-btn:last-child {
  margin-right: 0;
}

.nav-btn > a {
  line-height: 1;
  height: 100%;
  align-items: center;
  display: flex;
}

.navbar-nav .dropdown-menu {
  border: 4px solid black;
}

.dropdown-toggle::after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAyMSAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgMkwxMC41IDEwTDE5IDJNMTAuNSAxMEwxOSAyIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K');
  background-repeat: no-repeat;
  background-position: center center;
  content: '';
  border: 0;
  width: 17px;
  height: 8px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.header h2 {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 1.25;
  color: #000000;
  margin-bottom: 0;
}

.totals {
  background: #F9F9F9;
  border-radius: 10px;
  max-width: 345px;
  margin: 0 auto;
}

.totals ul {
  padding: 20px 30px;
  margin-bottom: 0;
}

.totals ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.totals ul li:last-child {
  margin-bottom: 0;
}

.totals ul li span {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #000000;
}

.totals ul li strong {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  color: black;
}

.stateCta .btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 40px;
  border-radius: 25px;
  margin: 0 auto;
  background-color: #000;
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #FFFFFF;
}

.stateOne .text {
  max-width: 300px;
  margin: 0 auto;
}

.text p {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 1em;
}

.stateTwo h3 {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.15;
  color: #000000;
  margin: 0 0 1em 5%;
  padding-bottom: 5%;
  position: relative;
  max-width: fit-content;
  max-width: -moz-fit-content;
}

.stateTwo h3::after {
  content: '';
  position: absolute;
  background-color: #000;
  border-radius: 10px;
  height: 4px;
  width: 92%;
  left: 50%;
  top: 60%;
  transform: translateX(-50%);
}

.stateTwo ul {
  margin-bottom: 15px;
}

.stateTwo ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}

.stateTwo ul li span {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
}

.stateTwo ul li strong {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  text-align: right;
  color: #000000;
}

.stateTwo .bg-gray {
  background-color: #F9F9F9;
  border-radius: 10px;
  max-height: 538px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 25px;
  max-width: 100%;
}

.images > .item > .inner {
  padding: 10px;
  border-radius: 10px;
}

.images > .item > .inner .img strong,
.images > .item > .inner .unstake p strong {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #000000;
  margin: 5px 0 7px;
}

.btn.btn-success {
  background-color: #36AD49;
  border-radius: 10px;
  border-color: #36AD49;

  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #FFFFFF;
  padding: 10px 20px;
  width: fit-content;
  width: -moz-fit-content;
}

.btn.btn-light {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  width: fit-content;
  width: -moz-fit-content;
}

.btn.btn-black {
  background-color: rgba(0, 0, 0, 1);
  border-radius: 10px;
  
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #fff;
  padding: 10px 20px;
  width: fit-content;
  width: -moz-fit-content;
}

.btn.btn-black .coin-number {
  font-size: 0.85em;
  margin-left: 5px;
}

.btn.btn-lg {
  padding: 1.25rem 1.75rem;
}
a:link {
  text-decoration: none;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .modal-dialog {
  border-radius: 10px;
  max-width: 370px;
  width: 100%;
}

.modal .modal-dialog .modal-content {
  border-color: transparent;
  padding: 33px 45px;
}

.modal .modal-dialog .modal-header {
  padding: 0 0 37px;
  border-bottom: 0;
}

.modal .modal-dialog .modal-header h1 {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.25;
  color: black;
}

.modal .modal-dialog .modal-header .btn-close {
  margin-right: 25px;
  margin-top: 25px;
  padding: 0;
  border-radius: 0;
  opacity: 1;
  height: 20px;
  width: 20px;
  background-size: 100%;
}

.modal .modal-dialog .modal-body {
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
}

.btn.btn-border {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 3px solid black;
  border-radius: 20px;
  padding: 20px;
}

.btn.btn-border img {
  margin-right: 20px;
}

.btn.btn-border span {
  font-family: var(--f_heading);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.15;
  color: #000000;
}

@media only screen and (max-width: 991px) {
  
  .navbar-nav > li,
  .nav-btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .img-size{
    width: 100%;
     height: auto;
     max-height: 100px;
  }
}
